import React from "react"
import PropTypes from "prop-types"

function PageTitle(props) {
  return (
    <div className="inline-block relative pr-6 pb-5 mb-8">{props.children}</div>
  )
}

PageTitle.propTypes = {
  children: PropTypes.any.isRequired,
}

export default PageTitle
