import React from "react"
import styled from "styled-components"
import H3 from "../../H3"
import {
  RiStore2Line,
  RiCodeSSlashLine,
  RiSmartphoneLine,
  RiGlobalLine,
  RiHeart2Line,
  RiAlarmLine,
  RiStarLine,
} from "react-icons/ri"
import ThingsToDo from "./ThingsToDo"
import FunBlocks from "./FunBlocks"
import Clients from "./Clients"

const SpanInfo = styled.span`
  &.title {
    color: #f50036;
    margin-right: 5px;
  }
`

const SpanSubTitle = styled.span`
  color: #f50036;
`

function AboutContent({ data }) {
  return (
    <>
      <div className="row flex flex-wrap mb-8 xl:mb-0 xl:justify-between">
        <div className="col xl:w-6/12 xl:mb-12">
          <p className="mb-3 tracking-wide text-left leading-6">
            {data.description}
          </p>
        </div>
        <div className="col xl:w-5/12">
          <ul className="mt-5 xl:mt-0 text-left">
            <li className="mb-2">
              <SpanInfo className="title">Residence:</SpanInfo>
              <SpanInfo className="font-thin">Colombia</SpanInfo>
            </li>
            <li className="mb-2">
              <SpanInfo className="title">Address:</SpanInfo>
              <SpanInfo className="font-thin">
                Pitalito - Huila
              </SpanInfo>
            </li>
            <li className="mb-2">
              <SpanInfo className="title">e-mail:</SpanInfo>
              <a href="mailto:ramir-aj64@hotmail.com">
                <SpanInfo className="font-thin">
                  contact@siranderss.com
                </SpanInfo>
              </a>
            </li>
            <li className="mb-2">
              <SpanInfo className="title">Phone:</SpanInfo>
              <a href="tel:+573134810480">
                <SpanInfo className="font-thin">+57 313 481 0480</SpanInfo>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row flex flex-wrap mb-12">
        <H3>
          {data.activitties.title[0]}{" "}
          <SpanSubTitle>{data.activitties.title[1]}</SpanSubTitle>
        </H3>
      </div>
      <div className="row flex flex-wrap xl:justify-between mb-12">
        <div className="col xl:w-6/12">
          <ThingsToDo
            title={data.activitties.activitties[0].title}
            content={data.activitties.activitties[0].content}
          >
            <RiGlobalLine className="relative text-5xl opacity-80" />
          </ThingsToDo>
          <ThingsToDo
            title={data.activitties.activitties[1].title}
            content={data.activitties.activitties[1].content}
          >
            <RiSmartphoneLine className="relative text-5xl opacity-80" />
          </ThingsToDo>
        </div>
        <div className="col xl:w-6/12">
          <ThingsToDo
            title={data.activitties.activitties[2].title}
            content={data.activitties.activitties[2].content}
          >
            <RiCodeSSlashLine className="relative text-5xl opacity-80" />
          </ThingsToDo>
          <ThingsToDo
            title={data.activitties.activitties[3].title}
            content={data.activitties.activitties[3].content}
          >
            <RiStore2Line className="relative text-5xl opacity-80" />
          </ThingsToDo>
        </div>
      </div>
      <div className="row flex flex-wrap mb-12">
        <H3>{data.clients}</H3>
      </div>
      <div className="row flex flex-wrap -mr-10 -ml-10 mb-12">
        <Clients />
      </div>
      <div className="row flex flex-wrap mb-12">
        <H3>
          {data.funFacts.title[0]}{" "}
          <SpanSubTitle>{data.funFacts.title[1]}</SpanSubTitle>
        </H3>
      </div>
      <div className="row flex flex-wrap xl:flex-row xl:flex-nowrap justify-between">
        <FunBlocks
          title={data.funFacts.data[0].title}
          amount={data.funFacts.data[0].amount}
        >
          <RiHeart2Line className="relative text-6xl xl:text-5xl mx-auto mb-2 opacity-80 text-primary" />
        </FunBlocks>
        <FunBlocks
          title={data.funFacts.data[1].title}
          amount={data.funFacts.data[1].amount}
        >
          <RiAlarmLine className="relative text-6xl xl:text-5xl mx-auto mb-2 opacity-80 text-primary" />
        </FunBlocks>
        <FunBlocks
          title={data.funFacts.data[2].title}
          amount={data.funFacts.data[2].amount}
        >
          <RiStarLine className="relative text-6xl xl:text-5xl mx-auto mb-2 opacity-80 text-primary" />
        </FunBlocks>
      </div>
    </>
  )
}

export default AboutContent
