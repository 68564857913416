import React from "react"
import PropTypes from "prop-types"

function ContactInfoItem(props) {
  return (
    <div className="relative text-center w-full block mb-4 pt-6 px-3 pb-4 bg-gray-700 shadow-none overflow-hidden transition transform duration-200 ease-in-out hover:translate-x-2 hover:bg-gray-600">
      {props.children}
      <h4 className="text-white text-lg my-2 font-semibold">{props.data}</h4>
    </div>
  )
}

ContactInfoItem.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ContactInfoItem
