import React, { useState, useRef } from "react"
import styled from "styled-components"
import emailjs from "emailjs-com"
import H3 from "../../H3"
import { Loader } from "../../Loader"

const SpanSubTitle = styled.span`
  color: #f50036;
`

const Input = styled.input.attrs((/* props */) => ({ tabIndex: 0 }))`
  position: relative;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  padding: 10px 25px 10px 12px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 12px;
  width: 100%;
  background: 0 0;
  background-color: transparent;
  text-align: left;
  color: inherit;
  box-shadow: none;
  outline: none;
  border: 2px solid #999;
  font-family: poppins, Helvetica, sans-serif;
  box-shadow: 0 10px 10px -8px rgb(0 0 0 / 10%);
  transition: all 0.4s cubic-bezier(0.45, 0.05, 0.55, 0.95);

  &.error {
    border: 2px solid #f50036;
  }

  &.success {
    border: 2px solid #00e49b;
  }

  &:focus {
    border: 2px solid #f50036;
  }
`

const TextArea = styled.textarea.attrs((/* props */) => ({ tabIndex: 0 }))`
  height: 179px;
  position: relative;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  padding: 10px 25px 10px 12px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 12px;
  width: 100%;
  background: 0 0;
  background-color: transparent;
  text-align: left;
  color: inherit;
  box-shadow: none;
  outline: none;
  border: 2px solid #999;
  font-family: poppins, Helvetica, sans-serif;
  box-shadow: 0 10px 10px -8px rgb(0 0 0 / 10%);
  transition: all 0.4s cubic-bezier(0.45, 0.05, 0.55, 0.95);

  &.error {
    border: 2px solid #f50036;
  }

  &.success {
    border: 2px solid #00e49b;
  }

  &:focus {
    border: 2px solid #f50036;
  }
`

const HelpBlock = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  left: -8px;
  padding: 5px;
  font-size: 0.93em;
  line-height: 1.25em;
  margin: 2px 0 0 10px;
  color: #fff;
  border-radius: 5px;
  background: #ff4e4e;

  &:after {
    content: " ";
    position: absolute;
    right: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid #ff4e4e;
    border-left: 10px solid transparent;
  }
`

const Button = styled.button`
  border: 2px solid #f50036;
`

function ContactForm({ data }) {
  const FormInput = useRef(null)
  const [message, setMessage] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })
  const [errors, setErrors] = useState([])
  const [sent, setSent] = useState(false)
  const [loader, setLoader] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target

    setMessage(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateEmail = e => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )

    return !pattern.test(e) ? true : false
  }

  const handleErrors = () => {
    let currentErrors = []

    Object.entries(message).forEach(([key, value]) =>
      value.length < 5
        ? currentErrors.push(key)
        : key === "email"
        ? validateEmail(value)
          ? currentErrors.push("email")
          : currentErrors
        : currentErrors
    )

    setErrors(currentErrors)

    return currentErrors.length === 0 ? false : true
  }

  const sendMail = async () => {
    if (!handleErrors()) {
      setLoader(true)
      await emailjs
        .send(
          "hyrdev",
          "template_ywu1ld5",
          message,
          "user_XGAGU3wnXYUK9ZKIadseA"
        )
        .then(() => {
          setSent(true)
          setLoader(false)
        })
        .catch(e => {
          setLoader(false)
          console.error(e)
        })
        .then(() => {
          setMessage({
            name: "",
            email: "",
            subject: "",
            message: "",
          })
        })
    }
  }

  return (
    <div className="pt-4 xl:pt-0">
      {loader ? (
        <Loader />
      ) : !sent ? (
        <>
          <div className="row flex flex-wrap mb-12 xl:mb-4">
            <H3>
              {data.title[0]} <SpanSubTitle>{data.title[1]} </SpanSubTitle>
            </H3>
          </div>
          <div className="w-full text-center">
            <div className="flex flex-col xl:flex-row justify-between">
              <div className="w-full mr-6">
                <div
                  className={`relative m-0 ${
                    errors.includes("name") ? "mb-10" : "mb-5"
                  }`}
                >
                  <Input
                    type="text"
                    name="name"
                    value={message.name}
                    className={
                      errors.length > 0
                        ? errors.includes("name")
                          ? "error"
                          : "success"
                        : ""
                    }
                    onChange={handleChange}
                    placeholder={data.form.name.name}
                    autoComplete="off"
                    ref={FormInput}
                  />
                  {errors.includes("name") ? (
                    <HelpBlock>{data.form.name.required}</HelpBlock>
                  ) : null}
                </div>
                <div
                  className={`relative m-0 ${
                    errors.includes("email") ? "mb-10" : "mb-5"
                  }`}
                >
                  <Input
                    type="email"
                    name="email"
                    value={message.email}
                    className={
                      errors.length > 0
                        ? errors.includes("email")
                          ? "error"
                          : "success"
                        : ""
                    }
                    onChange={handleChange}
                    placeholder={data.form.email.name}
                    ref={FormInput}
                  />
                  {errors.includes("email") ? (
                    <HelpBlock>{data.form.email.required}</HelpBlock>
                  ) : null}
                </div>
                <div
                  className={`relative m-0 ${
                    errors.includes("subject") ? "mb-10" : "mb-5"
                  }`}
                >
                  <Input
                    type="text"
                    name="subject"
                    value={message.subject}
                    className={
                      errors.length > 0
                        ? errors.includes("subject")
                          ? "error"
                          : "success"
                        : ""
                    }
                    onChange={handleChange}
                    placeholder={data.form.subject.name}
                    ref={FormInput}
                  />
                  {errors.includes("subject") ? (
                    <HelpBlock>{data.form.subject.required}</HelpBlock>
                  ) : null}
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`relative m-0 ${
                    errors.includes("content") ? "mb-10" : "mb-5"
                  }`}
                >
                  <TextArea
                    name="message"
                    value={message.message}
                    className={
                      errors.length > 0
                        ? errors.includes("message")
                          ? "error"
                          : "success"
                        : ""
                    }
                    onChange={handleChange}
                    placeholder={data.form.message.name}
                    rows="6"
                    ref={FormInput}
                  ></TextArea>
                  {errors.includes("message") ? (
                    <HelpBlock>{data.form.message.required}</HelpBlock>
                  ) : null}
                </div>
              </div>
            </div>
            <Button
              className="inline-block relative mt-5 py-3 px-10 mb-3 outline-none rounded-3xl text-white shadow-lg font-semibold"
              onClick={() => sendMail()}
            >
              {data.form.button}
            </Button>
          </div>
        </>
      ) : (
        <div className="w-full xl:h-72 py-4 xl:py-0 flex flex-col items-center justify-center text-center">
          <H3>
            {data.form.sent[0]},{" "}
            <SpanSubTitle>{data.form.sent[1]}</SpanSubTitle>
          </H3>
        </div>
      )}
    </div>
  )
}

export default ContactForm
