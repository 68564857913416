import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  FiUser,
  FiHome,
  FiAward,
  FiBriefcase,
  FiMessageCircle,
} from "react-icons/fi"

const MenuNote = styled.div`
  .active {
    transform: translateX(-1rem);
  }
`
function MenuItem(props) {
  const [width, setWidth] = useState(0)

  const handleClick = () => {
    props.handleMenu(props.item)
    if (width < 1024) props.setMenuBar(true)
  }

  useEffect(() => {
    const updateWidth = () => {
      const width = document.body.clientWidth

      setWidth(width)
    }

    updateWidth()

    window.addEventListener("resize", updateWidth)
  })

  return (
    <li
      className="relative block text-white text-lg py-3 leading-5 opacity-70 xl:opacity-100 font-light cursor-pointer"
      onClick={() => handleClick()}
      onMouseEnter={() => props.setMenuItem(props.item)}
      onMouseLeave={() => props.setMenuItem(0)}
      role="presentation"
    >
      <MenuNote move={props.menuItem === props.item}>
        <span
          className={`xl:absolute ${
            props.menuItem === props.item
              ? `visible xl:opacity-100 active`
              : `${
                  width > 1024 ? "xl:opacity-0 xl:invisible" : "xl:opacity-100"
                }`
          } right-8 xl:bg-white xl:text-gray-800 xl:w-24 xl:py-3 xl:rounded-l-lg transform transition ease-in-out delay-200 duration-300`}
        >
          {props.children}
        </span>
      </MenuNote>
      {props.item === 1 ? (
        <FiHome className="hidden xl:block text-4xl hover:text-rose-600 transition duration-300" />
      ) : props.item === 2 ? (
        <FiUser className="hidden xl:block text-4xl hover:text-rose-600 transition duration-300" />
      ) : props.item === 3 ? (
        <FiAward className="hidden xl:block text-4xl hover:text-rose-600 transition duration-300" />
      ) : props.item === 4 ? (
        <FiBriefcase className="hidden xl:block text-4xl hover:text-rose-600 transition duration-300" />
      ) : props.item === 5 ? (
        <FiMessageCircle className="hidden xl:block text-4xl hover:text-rose-600 transition duration-300" />
      ) : null}
    </li>
  )
}

MenuItem.propTypes = {
  children: PropTypes.string,
}

export default MenuItem
