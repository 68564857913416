import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

import About from "./about/About"
import Home from "./Home"
import Resume from "./resume/Resume"
import Portfolio from "./portfolio/Portfolio"
import Contact from "./contact/Contact"
// import { MenuAnimation } from "../../resources/animations"

const ContentArea = styled.div`
  position: absolute;
  right: 0;
  background-color: transparent;
  height: 100%;
  width: 100%;
  max-width: calc(100% - 320px);

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

const AnimatedSections = styled.div`
  position: relative;
  height: 100%;
  perspective: 1500px;
  backface-visibility: hidden;
`

const AnimatedSection = styled.div`
  transform: translate3d(0, 0, 0);
  background-position: 50% 50%;
`

const BarContainer = styled(SimpleBar)`
  .simplebar-scrollbar::before {
    background: #ffffffcf;
  }

  .simplebar-vertical {
    right: 4px;
    top: 32px !important;
    bottom: 32px !important;
  }
`

function Content({ menu, data }) {
  const scroll = useRef(null)

  useEffect(() => {
    function toTop() {
      if (menu !== 1) {
        return scroll.current.scrollTo({ top: 0, behavior: "smooth" })
      }
    }

    toTop()
  }, [menu])

  return (
    <ContentArea>
      <AnimatedSections className="relative h-full">
        {menu === 1 ? (
          <div className="w-full h-full min-h-full my-4 xl:my-0">
            <Home />
          </div>
        ) : (
          <AnimatedSection className="absolute h-full w-full rounded-3xl bg-no-repeat bg-cover overflow-auto ">
            <BarContainer
              className="min-h-full h-full w-full p-16"
              scrollableNodeProps={{ ref: scroll }}
            >
              {menu === 2 ? (
                <About data={data.about} />
              ) : menu === 3 ? (
                <Resume data={data.resume} />
              ) : menu === 4 ? (
                <Portfolio data={data.portfolio} />
              ) : menu === 5 ? (
                <Contact data={data.contact} />
              ) : null}
            </BarContainer>
          </AnimatedSection>
        )}
      </AnimatedSections>
    </ContentArea>
    // </ContentArea>
  )
}

export default Content
