import React from "react"
import PropTypes from "prop-types"

function PageContent(props) {
  return <div className="text-gray-200">{props.children}</div>
}

PageContent.propTypes = {
  children: PropTypes.any.isRequired,
}

export default PageContent
