import React from "react"
import styled from "styled-components"
import { codeSkills, frameworkSkills } from "../../../resources/skills"
import H3 from "../../H3"
import Skills from "./Skills"
import TimeLineItem from "./TimeLineItem"

const SpanSubTitle = styled.span`
  color: #f50036;
`

const LiKnowledge = styled.li`
  background-color: #f50036;
`

function ResumeContent({ data }) {
  return (
    <div>
      <div className="row flex flex-wrap">
        <div className="col xl:w-1/2">
          <div className="row flex flex-wrap mb-6">
            <H3>{data.education.title}</H3>
          </div>
          {data.education.content.map((item, key) => (
            <TimeLineItem
              key={key}
              year={item.year}
              institute={item.institute}
              title={item.title}
            >
              {item.description}
            </TimeLineItem>
          ))}
          <div className="white-space pb-12"></div>
          <div className="row flex flex-wrap mb-6">
            <H3>{data.experience.title}</H3>
          </div>
          {data.experience.content.map((item, key) => (
            <TimeLineItem
              key={key}
              year={item.year}
              institute={item.institute}
              title={item.title}
            >
              {item.description}
            </TimeLineItem>
          ))}
          <div className="white-space pb-12"></div>
        </div>
        <div className="col w-full xl:w-1/2">
          <Skills skills={codeSkills}>
            <div className="row flex flex-wrap mb-6">
              <H3>
                {data.skills.code[0]}{" "}
                <SpanSubTitle>{data.skills.code[1]}</SpanSubTitle>
              </H3>
            </div>
          </Skills>
          <div className="white-space pb-12"></div>
          <Skills skills={frameworkSkills}>
            <div className="row flex flex-wrap mb-6">
              <H3>
                {data.skills.frameworks[0]}{" "}
                <SpanSubTitle>{data.skills.frameworks[1]}</SpanSubTitle>
              </H3>
            </div>
          </Skills>
          <div className="white-space pb-12"></div>
          <div className="row flex flex-wrap mb-6">
            <H3>{data.knowledges.title}</H3>
          </div>
          <ul className="text-left">
            {data.knowledges.knowledges.map((item, key) => (
              <LiKnowledge
                key={key}
                className="inline-block rounded mx-1 my-0.5 py-1 px-3 cursor-default transform transition duration-300 hover:opacity-90"
              >
                {item}
              </LiKnowledge>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ResumeContent
