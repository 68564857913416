import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const TitleH2 = styled.h2`
  z-index: 1;

  &:after {
    position: absolute;
    opacity: 0.45;
    top: 1.1rem;
    right: 0;
    content: "";
    height: 30px;
    width: 50px;
    background-image: -webkit-repeating-radial-gradient(
      center center,
      #f50036,
      #f50036 1px,
      transparent 0px,
      transparent 100%
    );
    background-size: 6px 6px;
    z-index: 0;
  }
`

function H2(props) {
  return (
    <TitleH2 className="block text-white tracking-wider font-bold text-4xl">
      {props.children}
    </TitleH2>
  )
}

H2.propTypes = {
  children: PropTypes.any.isRequired,
}

export default H2
