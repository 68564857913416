import React, { useEffect, useState } from "react"
import styled from "styled-components"

import AvatarSpace from "./AvatarSpace"
import HeaderCopy from "./HeaderCopy"
import MainMenu from "./MainMenu"
import MenuNav from "./MenuNav"
import SocialLinks from "./SocialLinks"

const MenuButton = styled.button`
  width: 40px;
  height: 20px;
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.dots {
    &:after,
    &:before,
    span {
      width: 10%;
      height: 20%;
      border-radius: 100px;
      position: absolute;
      left: 0px;
      background: #fff;
      transform: rotate(0deg);
      transition: all 0.4s;

      left: 1%;
      right: 1%;
      margin: 0 auto;
    }

    &:after,
    &:before {
      content: "";
    }

    &:after {
      top: -10%;
      margin-top: 0px;
    }

    &:before {
      bottom: -10%;
      margin-bottom: 0px;
    }

    span {
      top: 50%;
      margin-top: -2px;
    }

    &.on {
      &:after {
        transform: rotate(135deg) translate(7px, -7px);
        width: 100%;
      }

      &:before {
        transform: rotate(225deg);
        bottom: 50%;
        margin-bottom: -2px;
        width: 100%;
      }

      span {
        transform: rotate(135deg);
      }
    }
  }
`

function Avatar({ handleMenu, menu, data, lang }) {
  const [menuBar, setMenuBar] = useState(false)
  const [render, setRender] = useState(false)

  useEffect(() => {
    const updateWidth = () => {
      const width = document.body.clientWidth

      if (width < 1024) {
        setMenuBar(true)
        setRender(true)
      } else {
        setMenuBar(false)
        setRender(true)
      }
    }

    updateWidth()

    window.addEventListener("resize", updateWidth)
  }, [])

  return (
    <>
      {render ? (
        <>
          <div
            className={`${
              menuBar
                ? "w-0 xl:left-0 right-0 -mr-28 overflow-hidden opacity-0 invisible shadow-none"
                : "w-full xl:left-0 right-0 opacity-100 visible overflow-auto xl:overflow-hidden"
            } absolute xl:h-full xl:flex flex-col justify-center max-w-xs top-0 bottom-0 bg-gray-700 z-50 p-12 text-center transition-all duration-300 ease-in-out xl:rounded-l-3xl`}
          >
            <AvatarSpace />
            <MainMenu
              handleMenu={handleMenu}
              setMenuBar={setMenuBar}
              data={data.menu}
            />
            <SocialLinks />
            <HeaderCopy data={data.button} lang={lang} />
          </div>
          <div className="fixed top-4 right-5 xl:hidden text-white text-4xl cursor-pointer z-50">
            <MenuButton
              className={`dots ${!menuBar ? "on" : ""}`}
              onClick={() => setMenuBar(!menuBar)}
              aria-label='Menu'
            >
              <span></span>
            </MenuButton>
          </div>
          <MenuNav handleMenu={handleMenu} menu={menu} />
        </>
      ) : null}
    </>
  )
}

export default Avatar
