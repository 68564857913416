import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

import myriam from "../../../images/myriam.webp"
import marco from "../../../images/marco.webp"
import eagle from "../../../images/eagle.webp"
import hyr from "../../../images/hrlogo.svg"

function Clients() {
  const settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <div className="block max-w-xs mx-auto">
      <Slider {...settings} className="overflow-hidden">
        <div>
          <div className="w-full h-full flex justify-center items-center opacity-80">
            <img
              src={marco}
              alt="Marco Arquitectónico"
              className="w-32 h-32 rounded-full bg-white"
            />
          </div>
        </div>
        <div>
          <div className="w-full h-full flex justify-center items-center opacity-80">
            <img
              src={eagle}
              alt="Eagle Institute"
              className="w-32 h-32 rounded-full bg-white"
            />
          </div>
        </div>
        <div>
          <div className="w-full h-full flex justify-center items-center opacity-80">
            <img
              src={myriam}
              alt="Myriam Camacho"
              className="w-32 h-32 rounded-full bg-white"
            />
          </div>
        </div>
        <div>
          <div className="w-full h-full flex justify-center items-center opacity-80">
            <img
              src={hyr}
              alt="H & R Development"
              className="w-32 h-32 rounded-full bg-white"
            />
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default Clients
