import React from "react"

function HeaderCopy({ data, lang }) {
  const currentYear = new Date()
  return (
    <div className="w-full text-center mt-8 xl:mt-6">
      {lang === "en" ? (
        <a
          href="https://firebasestorage.googleapis.com/v0/b/hr-devgroup.appspot.com/o/enCV.pdf?alt=media&token=7a9a4f20-c706-475a-b099-cad20f1f075b"
          target="_blank"
          rel="noreferrer"
          className="py-3 px-6 mb-4 text-lg text-white font-semibold tracking-wide border-2 rounded-l-full rounded-r-full hover:bg-white hover:text-gray-700 transition duration-300"
        >
          {data} CV
        </a>
      ) : (
        <a
          href="https://firebasestorage.googleapis.com/v0/b/hr-devgroup.appspot.com/o/esCV.pdf?alt=media&token=2423247a-db2b-46d5-bca5-9de72a7ebf56"
          target="_blank"
          rel="noreferrer"
          className="py-3 px-6 mb-4 text-lg text-white font-semibold tracking-wide border-2 rounded-l-full rounded-r-full hover:bg-white hover:text-gray-700 transition duration-300"
        >
          {data} CV
        </a>
      )}
      <p className="text-gray-300 text-xs mt-6">
        © {currentYear.getFullYear()} All rights reserved.
      </p>
    </div>
  )
}

export default HeaderCopy
