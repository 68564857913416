import React, { useState } from "react"
import styled from "styled-components"
import useWindowSize from "../resources/useWindowSize"
import { esp, en } from "../resources/siteData"
import col from "../images/col.png"
import us from "../images/us.png"

import { Blocks } from "../resources/animations"
import Avatar from "../components/Navigation/Avatar"
import Content from "../components/Content/Content"
import SEO from "../components/Seo"

const PageContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 72rem;
  min-height: 80vh;
  margin: 10vh auto;
  padding: 0;
  box-shadow: 0 0 100px -5px rgb(0 0 0 / 25%);
  border-radius: 1.5rem;
  backface-visibility: hidden;

  @media (max-width: 1024px) {
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
  }
`

const MainCard = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0 100px;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 0;
  }
`

const IndexPage = () => {
  const [menu, setMenu] = useState(1)
  const [language, setLanguage] = useState("esp")

  const handleMenu = val => setMenu(val)

  const size = useWindowSize()

  return (
    <>
      <SEO title="Portafolio Andersson Ramirez" />
      <div className="fixed left-4 bottom-2 z-50 flex flex-col xl:flex-row bg-white p-2 rounded-md">
        <img
          src={col}
          onClick={() => setLanguage("esp")}
          alt="Español"
          width="24px"
          height="24px"
          className="mb-4 xl:mr-2 xl:mb-0 shadow-md rounded-full transform transition duration-300 cursor-pointer hover:scale-110"
          aria-hidden="true"
        />
        <img
          src={us}
          onClick={() => setLanguage("en")}
          alt="English"
          width="24px"
          height="24px"
          className="shadow-md rounded-full transform transition duration-300 cursor-pointer hover:scale-110"
          aria-hidden="true"
        />
      </div>
      <div className="relative overflow-hidden flex justify-center items-center min-h-screen xl:bg-rose-600">
        {size.width > 1024 && <Blocks />}
        {/* {!isMobile ? <Blocks /> : null} */}
        <MainCard>
          <PageContent className="bg-gray-800">
            <Avatar
              handleMenu={handleMenu}
              menu={menu}
              data={language === "en" ? en.navigation : esp.navigation}
              lang={language}
            />
            <Content
              menu={menu}
              data={language === "en" ? en.content : esp.content}
            />
          </PageContent>
        </MainCard>
      </div>
    </>
  )
}

export default IndexPage
