import React from "react"
import H2 from "../../H2"
import PageTitle from "../PageTitle"
import PageContent from "../PageContent"
import AboutContent from "./AboutContent"

function About({ data }) {

  return (
    <>
      <PageTitle>
        <H2>
          {data.title[0]} <span className="text-primary">{data.title[1]}</span>
        </H2>
      </PageTitle>
      <PageContent>
        <AboutContent data={data.content} />
      </PageContent>
    </>
  )
}

export default About
