import React from "react"
import ContentTab from "./ContentTab"

function Home() {
  return (
    <>
      <ContentTab>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold mb-7">
            Andersson Ramírez
          </h1>
          <h3 className="text-gray-400 text-2xl font-light">
            FullStack Developer
          </h3>
        </div>
      </ContentTab>
    </>
  )
}

export default Home
