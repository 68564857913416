import React, { useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import anime from "animejs/lib/anime.js"

const Block = styled.div`
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.45);
`

const arr = Array.from(
  { length: Math.round(Math.random() * (120 - 80) + 80) },
  (v, i) => i
)

const generatedBlocks = () =>
  arr.map(el => (
    <Block
      key={el}
      className={`square absolute w-4 h-8 bg-red-${Math.round(
        Math.random() * (8 - 6) + 6
      )}00`}
    ></Block>
  ))

export const Blocks = () => {
  useEffect(() => {
    const animateBlocks = () =>
      anime({
        targets: ".square",
        translateX: () => anime.random(-800, 800),
        translateY: () => anime.random(-500, 500),
        scale: () =>
          anime.random(
            Math.round(Math.random() * (6 - 2) + 2),
            Math.round(Math.random() * (6 - 4) + 4)
          ),
        easing: "linear",
        duration: parseInt(`${Math.round(Math.random() * (5 - 2) + 2)}000`),
        delay: anime.stagger(10),
        complete: animateBlocks,
      })
    animateBlocks()
  }, [])

  return (
    <div className="absolute w-full h-full flex justify-center items-center">
      <div className="xl:block hidden">{generatedBlocks()}</div>
    </div>
  )
}

export const MenuAnimation = props => {
  useEffect(() => {
    const animateBlocks = () =>
      anime({
        targets: ".animation",
      })
    animateBlocks()
  }, [props.menu])

  return <>{props.children}</>
}

MenuAnimation.propTypes = {
  children: PropTypes.any.isRequired,
}
