import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function PortfolioContent() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      githubViewer {
        repositories {
          nodes {
            primaryLanguage {
              name
              color
            }
            description
            forkCount
            url
            homepageUrl
            name
            nameWithOwner
          }
        }
      }
    }
  `)

  return (
    <div className="w-full xl:flex flex-wrap justify-around">
      {data.githubViewer.repositories.nodes.map((item, key) =>
        item.name !== "vim" ? (
          item.name !== "SirAnderss" ? (
            <div
              className="flex flex-col justify-between border border-gray-500 bg-gray-600 text-left px-5 py-4 mb-4 mr-4 h-48 xl:w-72 transform transition duration-300 hover:-translate-y-2 hover:bg-gray-500 hover:border-gray-400"
              key={key}
            >
              <a
                className="text-primary transition duration-300 hover:underline"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4 className="text-lg mb-2">{item.name}</h4>
              </a>
              <p className="text-sm mb-4">
                {item.description
                  ? item.description.length > 65
                    ? `${item.description.substr(0, 65).trim()}...`
                    : item.description
                  : null}
              </p>
              <div>
                {item.primaryLanguage ? (
                  <>
                    <span
                      className="w-2 h-2 mr-4 rounded-full inline-block"
                      style={{ backgroundColor: item.primaryLanguage.color }}
                    ></span>
                    <span className="text-sm font-bold">
                      {item.primaryLanguage.name}
                    </span>
                  </>
                ) : null}
                {item.homepageUrl ? (
                  <a
                    href={item.homepageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="float-right text-primary transition duration-300 hover:underline"
                  >
                    Demo
                  </a>
                ) : null}
              </div>
            </div>
          ) : null
        ) : null
      )}
    </div>
  )
}

export default PortfolioContent
