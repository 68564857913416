import React from "react"
import styled from "styled-components"

const Clearfix = styled.div`
  &:before {
    content: " ";
    display: table;
  }
`

const SkillBar = styled.div`
  position: relative;
  display: inline-block;
  background-color: #222;
  border-radius: 8px;
  box-sizing: border-box;
  height: 10px;
  margin-bottom: 8px;
  width: 100%;
  border: 1px solid #f50036;

  &:before {
    content: "";
    position: absolute;
    background-color: #f50036;
  }
`

const SkillPercent = styled.div`
  background-color: #f50036;
  border: 2px solid #222;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  height: 8px;
  padding: 0;
`

function Skills(props) {
  return (
    <>
      {props.children}
      {props.skills.map((item, key) => (
        <div className="w-full pr-4 transform transition duration-300 ease-in-out hover:scale-105" key={key}>
          <Clearfix className="block w-full">
            <h4 className="text-sm leading-5 relative float-left mb-1 text-white">
              {item.lang}
            </h4>
            {/* <div className="text-sm leading-5 relative float-right mb-1">
              {item.percent} %
            </div> */}
          </Clearfix>
          <SkillBar>
            <SkillPercent style={{ width: `${item.percent}%` }} className="hover:opacity-80"></SkillPercent>
          </SkillBar>
        </div>
      ))}
    </>
  )
}

export default Skills
