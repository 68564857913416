import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const CardContainer = styled.div`
  transform: translate3d(0, 0, 0);
  background-position: 50% 50%;
`

const CardContent = styled.div`
  align-content: stretch;
`

function ContentTab(props) {
  return (
    <CardContainer className="rounded-none overflow-auto xl:overflow-hidden opacity-100 visible z-50 xl:h-full h-screen w-full min-h-full p-16 transform bg-no-repeat bg-center bg-cover absolute">
      <CardContent className="w-full h-full align-middle flex flex-row xl:flex-col flex-wrap justify-center items-center text-center">
        {props.children}
      </CardContent>
    </CardContainer>
  )
}

ContentTab.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ContentTab
