import React, { useState } from "react"
import MenuItem from "./MenuItem"

function MainMenu({ handleMenu, setMenuBar, data }) {
  const [menuItem, setMenuItem] = useState(0)

  return (
    <ul
      className={`my-4 xl:fixed xl:top-16 xl:right-4 2xl:right-64 xl:bg-gray-600 py-8 xl:px-4 xl:rounded-t-full xl:rounded-b-full xl:shadow-lg`}
    >
      <MenuItem
        item={1}
        menuItem={menuItem}
        handleMenu={handleMenu}
        setMenuItem={setMenuItem}
        setMenuBar={setMenuBar}
      >
        {data.home}
      </MenuItem>
      <MenuItem
        item={2}
        menuItem={menuItem}
        handleMenu={handleMenu}
        setMenuItem={setMenuItem}
        setMenuBar={setMenuBar}
      >
        {data.about}
      </MenuItem>
      <MenuItem
        item={3}
        menuItem={menuItem}
        handleMenu={handleMenu}
        setMenuItem={setMenuItem}
        setMenuBar={setMenuBar}
      >
        {data.resume}
      </MenuItem>
      <MenuItem
        item={4}
        menuItem={menuItem}
        handleMenu={handleMenu}
        setMenuItem={setMenuItem}
        setMenuBar={setMenuBar}
      >
        {data.portfolio}
      </MenuItem>
      <MenuItem
        item={5}
        menuItem={menuItem}
        handleMenu={handleMenu}
        setMenuItem={setMenuItem}
        setMenuBar={setMenuBar}
      >
        {data.contact}
      </MenuItem>
    </ul>
  )
}

export default MainMenu
