import React from "react"
import H2 from "../../H2"
import PageTitle from "../PageTitle"
import PageContent from "../PageContent"
import PortfolioContent from "./PortfolioContent"

function Portfolio({ data }) {
  return (
    <>
      <PageTitle>
        <H2>{data.title}</H2>
      </PageTitle>
      <PageContent>
        <PortfolioContent />
      </PageContent>
    </>
  )
}

export default Portfolio
