export const esp = {
  navigation: {
    button: "Descargar",
    menu: {
      home: "Inicio",
      about: "Sobre Mí",
      resume: "Resumen",
      portfolio: "Portafolio",
      contact: "Contacto",
    },
  },
  content: {
    about: {
      title: ["Sobre", "Mí"],
      content: {
        description:
          "Soy una persona que le gusta lo que hace, desde pequeño siempre me gustó la computación y empecé a programar desde los 14 años, aunque tuve que dejarla por casi cinco años; desde el 2016 retome la programación y decidí especializarme en el desarrollo web.",
        activitties: {
          title: ["Qué", "hago?"],
          activitties: [
            {
              title: "Desarrollo Web",
              content:
                "Desarrollo de sitios web estáticos y dinámicos para uso personal o para empresas, el servicio de desarrollo incluye asesoramiento y estudio de requerimientos para ofrecer la mejor solución.",
            },
            {
              title: "Desarrollo Móvil",
              content:
                "Diseño y desarrollo de aplicaciones móviles compatibles con Android y IO’s con una sola integración de código multiplataforma.",
            },
            {
              title: "RESTFul App's",
              content:
                "Desarrollo e implementación de API’s para aplicativos móviles, de escritorio y/o web; el desarrollo de API´s incluye documentación y pruebas para su implementación.",
            },
            {
              title: "Comercio Electrónico",
              content:
                "Soluciones para comercios electrónicos como el desarrollo de frontend, backend, soporte, métricas y analíticas, social media, etc.",
            },
          ],
        },
        clients: "Clientes",
        funFacts: {
          title: ["Algunos", "Datos"],
          data: [
            { title: "Clientes felices", amount: 32 },
            { title: "Horas de trabajo", amount: "4.780" },
            { title: "Proyectos terminados", amount: 57 },
          ],
        },
      },
    },
    resume: {
      title: "Resumen",
      content: {
        education: {
          title: "Educación",
          content: [
            {
              year: 2012,
              institute: "SENA",
              title: "Desarrollador de Software",
              description:
                "Aquí aprendí las bases de la programación, análisis de requerimientos, diseño de bases de datos, prototipado, desarrollo y despliegue.",
            },
            {
              year: 2020,
              institute: "Platzi",
              title: "Múltiples Títulos",
              description:
                "En Platzi decidí enfocarme en el estudio de inglés, desarrollo de empresa, algunas tecnologías como React, Laravel, TailwindCSS y otras más para ampliar mis habilidades de programador.",
            },
          ],
        },
        experience: {
          title: "Experiencia",
          content: [
            {
              year: "2016 - Ahora",
              institute: "Independiente",
              title: "Desarrollador Freelancer",
              description:
                "Durante aproximadamente 5 años me he dedicado de lleno a la programación web, algunos de los proyectos que he realizado como aplicaciones de control de estudiantes para escuelas, lectura de datos de sensores mediante puerto serial, control horario de repartidores, entre otros.",
            },
            {
              year: "2013 - 2016",
              institute: "Contratista",
              title: "Telecomunicaciones y administración de servidores",
              description:
                "Por giros de la vida tuve la oportunidad de trabajar en el área de telecomunicaciones de cobre y fibra óptica, durante este periodo de tiempo aprendi en gran medida como funcionan las redes y pude incursionar en la administración de servidores y conocer un poco del mundo de Linux.",
            },
          ],
        },
        skills: {
          code: ["Habilidades", "de Desarrollo"],
          frameworks: ["Habilidades", "con Frameworks"],
        },
        knowledges: {
          title: "Conocimientos",
          knowledges: [
            "Prototipado",
            "Resolución de Problemas",
            "Administración de Tiempo",
            "Comunicación",
            "Flexibilidad",
            "Consultoría en TI",
            "Redes Sociales",
          ],
        },
      },
    },
    portfolio: {
      title: "Portafolio",
    },
    contact: {
      title: "Contacto",
      content: {
        location: "Pitalito - Huila",
        phone: "+57 313 481 0480",
        email: "contact@siranderss.com",
        freelance: "Freelancer disponible",
        form: {
          title: ["¿Como Puedo", "Ayudarte?"],
          form: {
            name: {
              name: "Nombre Completo",
              required: "El nombre es requerido",
            },
            email: {
              name: "Correo electrónico",
              required: "El e-mail es requerido",
            },
            subject: {
              name: "Asunto",
              required: "El asunto es requerido",
            },
            message: {
              name: "Mensaje",
              required: "Por favor, dejame un mensaje",
            },
            button: "Enviar Mensaje",
            sent: ["E-mail enviado", "Pronto me pondré en contacto contigo"],
          },
        },
      },
    },
  },
}

export const en = {
  navigation: {
    button: "Download",
    menu: {
      home: "Home",
      about: "About Me",
      resume: "Resume",
      portfolio: "Portfolio",
      contact: "Contact",
    },
  },
  content: {
    about: {
      title: ["About", "Me"],
      content: {
        description:
          "I like what I do, since I was little I always liked computing and I started programming from the age of 14, although I had to leave programming for almost five years and since 2016 I resumed programming and decided to specialize in web development.",
        activitties: {
          title: ["What", "I Do?"],
          activitties: [
            {
              title: "Web Development",
              content:
                "Development of static and dynamic websites for personal or business use, the development service includes counseling and requirements study to offer the best solution.",
            },
            {
              title: "Mobile Development",
              content:
                "Design and development of mobile applications compatible with Android and IO's with a single cross-platform code integration.",
            },
            {
              title: "RESTFul App's",
              content:
                "Development and implementation of API's for mobile, desktop and / or web applications; API development includes documentation and tests for its implementation.",
            },
            {
              title: "E-commerce",
              content:
                "Solutions for e-commerce such as frontend development, backend, support, metrics and analytics, social media, etc.",
            },
          ],
        },
        clients: "Clients",
        funFacts: {
          title: ["Fun", "Facts"],
          data: [
            { title: "Happy Clients", amount: 32 },
            { title: "Working Hours", amount: "4.780" },
            { title: "Finished Projects", amount: 57 },
          ],
        },
      },
    },
    resume: {
      title: "Resume",
      content: {
        education: {
          title: "Education",
          content: [
            {
              year: 2012,
              institute: "SENA",
              title: "Software Developer",
              description:
                "Here I learned the basics of programming, requirements analysis, database design, prototyping, development and deployment.",
            },
            {
              year: 2020,
              institute: "Platzi",
              title: "Multiple Titles",
              description:
                "At Platzi I decided to focus on studying English, business development, some technologies such as React, Laravel, TailwindCSS and others to expand my programming skills.",
            },
          ],
        },
        experience: {
          title: "Experience",
          content: [
            {
              year: "2016 - Now",
              institute: "Independent",
              title: "Freelance Developer",
              description:
                "For approximately 5 years I have dedicated myself fully to web programming, some of the projects that I have carried out such as student control applications for schools, reading sensor data through a serial port, time control of dispatchers, among others.",
            },
            {
              year: "2013 - 2016",
              institute: "Contractor",
              title: "Telecommunications and server management",
              description:
                "Through turns of life I had the opportunity to work in the area of copper and fiber optic telecommunications, during this period of time I learned to a great extent how networks work and I was able to venture into server administration and learn a little about the world of Linux.",
            },
          ],
        },
        skills: {
          code: ["Coding", "Skills"],
          frameworks: ["Famework", "Skills"],
        },
        knowledges: {
          title: "Knowledge",
          knowledges: [
            "Prototyping",
            "Problem Solving",
            "Time Management",
            "Communication",
            "Flexibility",
            "IT Consulting",
            "Social Media",
          ],
        },
      },
    },
    portfolio: {
      title: "Portfolio",
    },
    contact: {
      title: "Contact",
      content: {
        location: "Pitalito - Huila",
        phone: "+57 313 481 0480",
        email: "contact@siranderss.com",
        freelance: "Freelance Available",
        form: {
          title: ["How Can I", "Help You?"],
          form: {
            name: {
              name: "Full Name",
              required: "Name is required",
            },
            email: {
              name: "Email Address",
              required: "Valid email is required",
            },
            subject: {
              name: "Subject",
              required: "Subject is required",
            },
            message: {
              name: "Message",
              required: "Please, leave me a message.",
            },
            button: "Send Message",
            sent: ["E-mail sent", "I'll contact you soon"],
          },
        },
      },
    },
  },
}
