import React from "react"
import ContactInfoItem from "./ContactInfoItem"
import {
  IoLocationOutline,
  IoCallOutline,
  IoMailOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5"
import ContactForm from "./ContactForm"

function ContactContent({ data }) {
  return (
    <div className="row flex flex-wrap xl:justify-around w-full h-full ">
      <div className="col w-full xl:w-4/12">
        <ContactInfoItem data={data.location}>
          <IoLocationOutline className="relative text-4xl mx-auto mb-2 opacity-80 text-primary" />
        </ContactInfoItem>
        <ContactInfoItem data={data.phone}>
          <IoCallOutline className="relative text-4xl mx-auto mb-2 opacity-80 text-primary" />
        </ContactInfoItem>
        <ContactInfoItem data={data.email}>
          <IoMailOutline className="relative text-4xl mx-auto mb-2 opacity-80 text-primary" />
        </ContactInfoItem>
        <ContactInfoItem data={data.freelance}>
          <IoCheckmarkCircleOutline className="relative text-4xl mx-auto mb-2 opacity-80 text-primary" />
        </ContactInfoItem>
      </div>
      <div className="col w-full xl:w-7/12">
        <iframe
          title="Home Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15950.927986324426!2d-76.0549189!3d1.8529805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e250b7dba6f64ff%3A0x7a99e7b32e8fa32c!2sPitalito%2C%20Huila!5e0!3m2!1ses!2sco!4v1629084544294!5m2!1ses!2sco"
          className="w-full h-64 xl:h-56 border-0 mb-6"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        <ContactForm data={data.form} />
      </div>
    </div>
  )
}

export default ContactContent
