import React from "react"
import H2 from "../../H2"
import PageTitle from "../PageTitle"
import PageContent from "../PageContent"
import ResumeContent from "./ResumeContent"

function Resume({ data }) {
  return (
    <>
      <PageTitle>
        <H2>{data.title}</H2>
      </PageTitle>
      <PageContent>
        <ResumeContent data={data.content} />
      </PageContent>
    </>
  )
}

export default Resume
