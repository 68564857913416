import React from "react"
import PropTypes from "prop-types"

function FunBlocks(props) {
  return (
    <div className="relative text-center w-full xl:w-56 block mb-4 pt-6 px-3 pb-4 rounded-xl bg-gray-700 border-2 border-gray-400 shadow-none overflow-hidden transition duration-200 ease-in-out transform hover:-translate-y-2">
      {props.children}
      <h4 className="text-white text-2xl my-5 font-semibold">{props.title}</h4>
      <p className="text-4xl font-light leading-snug block mx-auto mt-4 mb-4">
        {props.amount}
      </p>
    </div>
  )
}

FunBlocks.propTypes = {
  children: PropTypes.any.isRequired,
}
export default FunBlocks
