import React from "react"
import styled from "styled-components"
import avatar from "../../images/avatar.svg"

const HeaderPhoto = styled.div`
  max-width: 130px;
  margin-bottom: 25px;
  position: relative;
  width: 180px;
  margin: 0 auto 30px;
  z-index: 1;

  &::after {
    position: absolute;
    opacity: 0.3;
    top: 7%;
    left: 7%;
    border-radius: 300px;
    content: "";
    height: calc(100% + 0px);
    width: calc(100% + 0px);
    background-image: -webkit-repeating-radial-gradient(
      center center,
      #fff,
      #fff 1px,
      transparent 0px,
      transparent 100%
    );
    background-image: -moz-repeating-radial-gradient(
      center center,
      #fff,
      #fff 1px,
      transparent 0px,
      transparent 100%
    );
    background-image: -ms-repeating-radial-gradient(
      center center,
      #fff,
      #fff 1px,
      transparent 0px,
      transparent 100%
    );
    background-size: 6px 6px;
    z-index: -1;
  }
`

const AvatarImg = styled.img`
  max-width: 100%;
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: 300px;
`

function AvatarSpace() {
  return (
    <div className="header-content">
      <HeaderPhoto>
        <AvatarImg
          className="max-w-full bg-white border-4 rounded-full"
          src={avatar}
          alt="Andersson Ramírez"
        />
      </HeaderPhoto>
      <div className="title">
        <h1 className="text-4xl font-medium tracking-wider text-white mb-4">
          Andersson Ramírez
        </h1>
        <p className="text-lg text-gray-300 my-4">FullStack Developer</p>
      </div>
    </div>
  )
}

export default AvatarSpace
