import React from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

function MenuNav({ handleMenu, menu }) {
  return (
    <ul className="my-0 xl:my-4 fixed bottom-4 right-4 xl:bottom-16 xl:right-4 2xl:right-64 bg-gray-600 py-6 px-3 xl:py-8 xl:px-4 rounded-t-full rounded-b-full border-2 border-primary xl:border-0 shadow-lg z-40">
      <li className="relative text-white text-lg py-1 xl:py-3 leading-5 opacity-100 font-light cursor-pointer">
        <FiChevronLeft
          className="text-3xl xl:text-4xl hover:text-rose-600 transition duration-300"
          onClick={() => (menu === 1 ? handleMenu(5) : handleMenu(menu - 1))}
        />
      </li>
      <li className="relative block text-white text-lg py-1 xl:py-3 leading-5 opacity-100 font-light cursor-pointer">
        <FiChevronRight
          className="text-3xl xl:text-4xl hover:text-rose-600 transition duration-300"
          onClick={() => (menu === 5 ? handleMenu(1) : handleMenu(menu + 1))}
        />
      </li>
    </ul>
  )
}

export default MenuNav
