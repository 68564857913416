import React from "react"
import styled from "styled-components"

const Clearfix = styled.div`
  &:before {
    content: " ";
    display: table;
  }
`

const Divider = styled.div`
  width: 2px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    margin-top: 8px;
    width: 17px;
    height: 17px;
    position: absolute;
    margin-left: -8px;
    border-radius: 10px;
    background-color: #f50036;
    opacity: 0.25;
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    margin-top: 12px;
    width: 9px;
    height: 9px;
    margin-left: -4px;
    background-color: #222;
    border-radius: 5px;
    border: 2px solid #f50036;
    z-index: 1;
  }
`

function TimeLineItem(props) {
  return (
    <Clearfix>
      <Clearfix className="relative block text-left xl:mr-4 xl:pr-2 transform transition duration-300 hover:bg-gray-600">
        <div className="left-part w-full block pl-6 min-h-full text-left">
          <h5 className="my-1 text-sm leading-6 font-bold text-gray-400">
            {props.year}
          </h5>
          <span className="block mb-1 text-xs font-light leading-6 text-gray-500">
            {props.institute}
          </span>
        </div>
        <Divider className="divider absolute top-0 bottom-0 bg-gray-600"></Divider>
        <div className="right-part w-full block pl-6 pb-5">
          <h4 className="mb-2 font-bold text-white">{props.title}</h4>
          <p>{props.children}</p>
        </div>
      </Clearfix>
    </Clearfix>
  )
}

export default TimeLineItem
