export const codeSkills = [
  { lang: "Javascript", percent: 100 },
  { lang: "Typescript", percent: 100 },
  { lang: "PHP", percent: 100 },
  { lang: "MySQL, Postgress, ORACLE", percent: 100 },
  { lang: "HTML/CSS", percent: 100 },
  { lang: "Python", percent: 100 },
]

export const frameworkSkills = [
  { lang: "Laravel", percent: 100 },
  { lang: "Django", percent: 100 },
  { lang: "VueJS", percent: 100 },
  { lang: "React", percent: 100 },
  { lang: "React Native", percent: 100 },
  { lang: "Tailwind", percent: 100 },
]
