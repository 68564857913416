import React from "react"
import { FiTwitter, FiFacebook, FiGithub, FiLinkedin } from "react-icons/fi"

function SocialLinks() {
  return (
    <ul className="social w-4/5 mx-auto my-5 xl:mt-6 flex justify-around">
      <li>
        <a
          href="https://github.com/SirAnderss"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiGithub className="w-12 h-12 text-gray-200 p-2 border-0 text-xl transition-colors duration-300 ease-in-out hover:opacity-90 hover:text-primary" />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/Sr.Anderssson/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiFacebook className="w-12 h-12 text-gray-200 p-2 border-0 text-xl transition-colors duration-300 ease-in-out hover:opacity-90 hover:text-primary" />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/siranderss/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiLinkedin className="w-12 h-12 text-gray-200 p-2 border-0 text-xl transition-colors duration-300 ease-in-out hover:opacity-90 hover:text-primary" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/SirAnderss"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiTwitter className="w-12 h-12 text-gray-200 p-2 border-0 text-xl transition-colors duration-300 ease-in-out hover:opacity-90 hover:text-primary" />
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks
