import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ItemContent = styled.span`
  color: #f50036;
`

function ThingsToDo(props) {
  return (
    <div className="text-left mb-10">
      <ItemContent>{props.children}</ItemContent>
      <h3 className="text-white text-2xl font-semibold tracking-wide my-4">{props.title}</h3>
      <p className="leading-6 pr-10">{props.content}</p>
    </div>
  )
}

ThingsToDo.propTypes = {
  children: PropTypes.any.isRequired,
}
export default ThingsToDo
