import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const TitleH3 = styled.h2`
  z-index: 1;

  &:after {
    position: absolute;
    opacity: 0.45;
    top: 1rem;
    right: -0.7rem;
    content: "";
    height: 20px;
    width: 30px;
    background-image: -webkit-repeating-radial-gradient(
      center center,
      #f50036,
      #f50036 1px,
      transparent 0px,
      transparent 100%
    );
    background-size: 6px 6px;
    z-index: -1;
  }
`

function H3(props) {
  return (
    <TitleH3 className="block relative text-white font-semibold text-2xl">
      {props.children}
    </TitleH3>
  )
}

H3.propTypes = {
  children: PropTypes.any.isRequired,
}

export default H3
